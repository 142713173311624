import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { decodeEntities } from '../utils/htmlParse';

export const Navigation = ({ location, closeMenu = null }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              wordpressUrl
            }
          }
          primaryNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "primary-navigation" }) {
            navItems: items {
              title
              url
              classes
              slug: object_slug
            }
          }
        }
      `}
      render={(data) => {
        const {
          primaryNavigation: {
            navItems
          },
          site: {
            siteMetadata: {
              wordpressUrl
            }
          }
        } = data;
        return (
          <nav className='navigation'>
            {navItems && (
              <ul className="navigation-nav-items">
                {navItems.map(navItem => {
                  const { title, url, classes } = navItem;
                  const splat = url.replace(wordpressUrl, '');
                  return (
                    <li key={url} className={`nav-item ${classes} ${(location && splat === location.pathname) ? 'active' : ''}`}>
                      <Link to={splat} onClick={() => closeMenu ? closeMenu() : null}>{`${decodeEntities(title)}`}</Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </nav>
        )
      }}
    />
  );
}
