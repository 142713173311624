import React from 'react';
import { Link } from 'gatsby';
import Image from '../components/Image';
import { getPageSlugFromWpLink } from '../utils/helpers';
import './IntroBlock.scss';

export const IntroBlock = (props) => {
  const { backgroundColor = '', title, content, image, buttonLink, buttonText, layout, wordpressUrl } = props;
  return (
    <section className={`intro-section ${layout ? 'right-aligned-image' : 'left-aligned-image'} ${backgroundColor}`}>
      <div className="wrapper">
        <div className="intro-section-image">
          <div className="image-wrap"><Image image={image}/></div>
        </div>
        <div className="intro-section-content">
          <h2>{title}</h2>
          <div className="intro-section-content-body" dangerouslySetInnerHTML={{ __html: content }} />
          {buttonLink && <Link to={getPageSlugFromWpLink(buttonLink, wordpressUrl)} className="button dark">{buttonText || 'More Information'}</Link>}
        </div>
      </div>
    </section>
  )
}
