import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ProjectSlider from '../components/ProjectSlider';
import Image from '../components/Image';
import './RecentProjects.scss';

// const getCategoryString = (ids, data) => {
//   if (!ids || ids.length < 1 || !data || !data.edges) return null;
//   const catNames = [];
//   ids.map(id => {
//     return data.edges.find(edge => {
//       if (edge.node.id === id) return catNames.push(edge.node.name);
//       return null;
//     })
//   });
//   return catNames.join(', ');
// }

export const formatProjectBody = (project) => {
  const { node } = project;
  return ({
    id: node.id,
    image: node.image,
    cost: node.acf.projectCost,
    title: node.title,
    location: node.acf.projectLocation,
    duration: node.acf.projectDuration,
    category: node.categories.map(category => category.name).join(', '),
    date: node.date,
    caption: node.acf.recentProjectsCaption,
    content: node.content,
    slug: node.slug
  })
}

export const RecentProjects = ({ title, subtitle, content, projects: projectsFromProps = null }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            blogSlug
          }
        }
        background: file(relativePath: { eq: "images/recent-projects-bg.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200, quality:90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        allWordpressWpProjects(limit: 9, filter: { slug: { ne: "build-project" } }) {
          edges {
            node {
              id,
              title
              content
              slug
              image: featured_media {
                localFile {
                  childImageSharp {
                    fixed(width: 440, height:264) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
              categories {
                name
                slug
              }
              date
              acf {
                recentProjectsCaption
                projectIntro
                projectCost
                projectClient
                projectLocation
                projectDuration
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { allWordpressWpProjects, background, site } = data;
      const { edges: projectsFromData } = allWordpressWpProjects;
      // If projects are passed in from props, use those instead of recent projects from data
      const projects = projectsFromProps || projectsFromData;
      return (
        <section className="recent-projects">
          <div className="wrapper" id="project-slider-offset">
            <Image image={{localFile: { ...background }}} />
            <div className="recent-projects-content-container">
              <h3 className="recent-projects-title">
                <span>{title}</span>
                {subtitle}
              </h3>
              <div className="recent-projects-content" dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
          <div className="project-slider-container">
            <ProjectSlider
              projects={[
                ...projects.map(item => formatProjectBody(item)),
              ]}
              buttonText="Discover More"
              buttonLink={`/${site.siteMetadata.blogSlug}/`}
            />
          </div>
        </section>
      )
    }}
  />
)
