import React from 'react';
import './SocialLink.scss';

const FACEBOOK_TYPE = 'facebook';
const TWITTER_TYPE = 'twitter';
const LINKEDIN_TYPE = 'linkedin';
const GOOGLE_PLUS_TYPE = 'google_plus';
const INSTAGRAM_TYPE = 'instagram';
const YOUTUBE_TYPE = 'youtube';

export const SocialLink = (props) => {
  const {
    socialLink,
    theme = 'light',
    size = 'small',
  } = props;
  const {
    type,
    url,
  } = socialLink;
  const typeClass = type.replace(/_/g, '-');
  switch (type) {
    case FACEBOOK_TYPE:
      return (
        <a
          className={`social-link ${typeClass} ${theme} ${size}`}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg viewBox="0 0 7 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.513 16H4.62V7.996h2.085s.195-1.292.29-2.704H4.633V3.45c0-.275.338-.645.674-.645H7V0H4.697c-3.26 0-3.184 2.696-3.184 3.099v2.202H0v2.694h1.513V16z" fill="#3C4244" fillRule="evenodd" />
          </svg>
        </a>
      );
    case TWITTER_TYPE:
      return (
        <a
          className={`social-link ${typeClass} ${theme} ${size}`}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg viewBox="0 0 16 13" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 11.526A9.29 9.29 0 0 0 5.032 13c6.038 0 9.34-5 9.34-9.338 0-.142-.004-.284-.01-.425A6.664 6.664 0 0 0 16 1.54a6.56 6.56 0 0 1-1.885.517A3.292 3.292 0 0 0 15.558.24a6.57 6.57 0 0 1-2.085.796A3.282 3.282 0 0 0 7.88 4.03 9.32 9.32 0 0 1 1.115.602 3.28 3.28 0 0 0 2.13 4.983a3.271 3.271 0 0 1-1.487-.411v.04a3.284 3.284 0 0 0 2.633 3.22 3.295 3.295 0 0 1-1.483.055 3.286 3.286 0 0 0 3.067 2.28A6.587 6.587 0 0 1 0 11.525z" fill="#3C4244" fillRule="evenodd" />
          </svg>
        </a>
      );
    case LINKEDIN_TYPE:
      return (
        <a
          className={`social-link ${typeClass} ${theme} ${size}`}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <g fill="#3C4244" fillRule="evenodd">
              <path d="M.394 15.045h3.254V5.257H.394zM2 3.92h.021c1.135 0 1.84-.75 1.84-1.69-.02-.961-.705-1.692-1.818-1.692C.929.538.2 1.268.2 2.23.2 3.17.908 3.921 2 3.921zM8.703 15.045V9.58c0-.294.022-.585.107-.794.236-.585.77-1.19 1.67-1.19 1.178 0 1.648.898 1.648 2.213v5.237h3.255V9.432c0-3.006-1.606-4.405-3.747-4.405-1.73 0-2.501.951-2.933 1.617v.032H8.68l.022-.032V5.257H5.448c.043.918 0 9.788 0 9.788h3.255z" />
            </g>
          </svg>
        </a>
      );
    case GOOGLE_PLUS_TYPE:
      return (
        <a
          className={`social-link ${typeClass} ${theme} ${size}`}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg viewBox="0 0 22 14" xmlns="http://www.w3.org/2000/svg">
            <defs><path id="a" d="M0 .038h13.96V14H0z" /></defs>
            <g fill="none" fillRule="evenodd">
              <g>
                <path d="M7.656 8.46h3.41a4.338 4.338 0 0 1-4.117 2.89 4.344 4.344 0 0 1-4.296-4.16 4.336 4.336 0 0 1 4.328-4.502c1.118 0 2.138.426 2.908 1.124a.47.47 0 0 0 .638-.003l1.253-1.178a.468.468 0 0 0 0-.68A6.956 6.956 0 0 0 7.12.038C3.271-.036.027 3.12 0 6.97a6.981 6.981 0 0 0 13.95.443c.006-.047.01-1.603.01-1.603H7.656a.468.468 0 0 0-.468.468v1.714c0 .259.21.468.468.468" fill="#3C4244" />
              </g>
              <path d="M20.045 5.955v-1.57A.384.384 0 0 0 19.661 4h-1.322a.384.384 0 0 0-.384.384v1.57h-1.57A.384.384 0 0 0 16 6.34v1.322c0 .212.172.384.384.384h1.57v1.57c0 .213.173.385.385.385h1.322a.384.384 0 0 0 .384-.384v-1.57h1.57A.384.384 0 0 0 22 7.66V6.339a.384.384 0 0 0-.384-.384h-1.57z" fill="#3C4244" />
            </g>
          </svg>
        </a>
      );
    case INSTAGRAM_TYPE:
      return (
        <a
          className={`social-link ${typeClass} ${theme} ${size}`}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 25">
            <defs>
              <path id="a" d="M0 0h26v25H0z" />
            </defs>
            <g fill="none" fillRule="evenodd">
              <mask id="b" fill="#fff">
                <use xlinkHref="#a" />
              </mask>
              <path fill="#FFF" d="M20.668 4.395c-.42 0-.761.328-.761.732 0 .404.341.733.761.733s.762-.329.762-.733c0-.404-.342-.732-.762-.732m0 2.93c-1.26 0-2.285-.986-2.285-2.198 0-1.211 1.025-2.197 2.285-2.197 1.26 0 2.285.986 2.285 2.197 0 1.212-1.025 2.198-2.285 2.198m-7.617 11.718c-3.78 0-6.855-2.957-6.855-6.592S9.27 5.86 13.05 5.86c3.78 0 6.856 2.956 6.856 6.59 0 3.636-3.076 6.593-6.856 6.593M3.81 0C1.709 0 0 1.643 0 3.662v17.676C0 23.358 1.709 25 3.809 25h18.383c2.1 0 3.808-1.643 3.808-3.662V3.662C26 1.643 24.292 0 22.192 0H3.809zm9.242 7.764c-2.53 0-4.588 2.103-4.588 4.687 0 2.585 2.058 4.688 4.588 4.688 2.53 0 4.588-2.103 4.588-4.688 0-2.584-2.058-4.687-4.588-4.687" mask="url(#b)" />
            </g>
          </svg>
        </a>
      );
    case YOUTUBE_TYPE:
      return (
        <a
          className={`social-link ${typeClass} ${theme} ${size}`}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 46">
            <path fill="#000" fillRule="nonzero" d="M54.5.5h-43C5.425.5.5 5.425.5 11.5v23c0 6.075 4.925 11 11 11h43c6.075 0 11-4.925 11-11v-23c0-6.075-4.925-11-11-11zM35.75 28L26 33V13l9.75 5 9.75 5-9.75 5z" />
          </svg>
        </a>
      );
    default:
      return null;
  }
};
