import React from 'react';
import { Link } from 'gatsby';
import Image from '../components/Image';
import gridLight from '../images/grid-light.svg';
import { getPageSlugFromWpLink } from '../utils/helpers';
import './ImageWithContent.scss';

export const ImageWithContent = (props) => {
  const {
    image,
    title,
    layout,
    content,
    hasButton,
    buttonText,
    buttonLink,
    hasColumnText,
    columns,
    wordpressUrl
  } = props;
  console.log(wordpressUrl);
  console.log(buttonLink);
  console.log(getPageSlugFromWpLink(buttonLink, wordpressUrl));
  const shuffleString = (arr) => arr.split('').sort(() => Math.random() - 0.5).join('');
  return (
    <section className={`image-with-content ${layout ? 'image-left' : ''}`}>
      <div className="wrapper image-with-content-wrapper">
        <div className="main-content">
          <div className="image-wrap image"><Image image={image} /></div>
          <div className="content">
            <h2 dangerouslySetInnerHTML={{__html: title}} />
            <div className="image-with-content-content-body" dangerouslySetInnerHTML={{__html: content}} />
            {hasButton && <Link className="button dark" to={getPageSlugFromWpLink(buttonLink, wordpressUrl)}>{buttonText || `Learn more`}</Link>}
          </div>
        </div>
        {hasColumnText && (
          <div className="image-with-content-three-columns">
            {columns && columns.map(column => {
              return (
                <div
                  className="col"
                  key={
                    column.title ?
                    shuffleString(column.title.substring(0,20)) :
                    shuffleString(column.content.substring(0,20))
                  }
                >
                  <h3 dangerouslySetInnerHTML={{__html: column.title}} />
                  <div className="col-content" dangerouslySetInnerHTML={{__html: column.content}} />
                </div>
              );
            })}
          </div>
        )}
        <img className="grid-texture" src={gridLight} alt="Img with content texture" />
      </div>
    </section>
  );
}
