import React, { Component } from 'react';
import Image from '../components/Image';
import { scrollTo } from '../utils/helpers';
import { ServiceSvg } from '../components/ServiceSvg';
import './Timeline.scss';

export class Timeline extends Component {
  constructor(props) {
    super(props);
    this.ScrollMagic = typeof window !== 'undefined' ? require('scrollmagic') : null; // eslint-disable-line global-require
    this.controller = this.ScrollMagic ? new this.ScrollMagic.Controller() : null;
  }

  componentDidMount() {
    this.createScrollMagicScenes();
  }

  componentWillUnmount() {
    if (this.controller) this.controller.destroy(true);
  }

  updateSceneElements = (elements, progress) => {
    Object.keys(elements).forEach(element => {
      const animatedElement = elements[element];
      if (animatedElement) {
        // Image scale up
        if (element === 'image') {
          if (progress > 0.2) {
            const imageScale = `scale(${progress})`;
            animatedElement.style.transform = imageScale;
          }
        }
        // Timeline line draw animation
        if (element === 'line') {
          const lineHeight = `${100 * progress}%`;
          animatedElement.style.height = lineHeight;
        }
        // Background textures movement
        if (element === 'textures') {
          const textureTranslateY = `${50 * progress}px`;
          animatedElement.style.transform = `translateY(${textureTranslateY})`;
        }
        // Body text fade in
        if (element === 'body') {
          if (progress >= 0.7) {
            animatedElement.classList.add('visible');
          }
          if (progress < 0.7) {
            animatedElement.classList.remove('visible');
          }
        }
        // Next Button fade in
        if (element === 'nextButton') {
          if (progress >= 0.1) {
            animatedElement.classList.add('visible');
          }
          if (progress < 0.1) {
            animatedElement.classList.remove('visible');
          }
        }
      }
    })
  }

  createScrollMagicScenes = () => {
    const { timeline } = this.props;
    if (timeline) {
      const scenesArray = timeline.map((timelineItem, index) => {
        const timelineNavElement = document.querySelector('#timeline-navigation');
        const scrollIconElement = document.querySelector('#timeline-icon-scroll');
        const elementSelector = `#timeline-item-${index}`;
        const navItemElement = document.querySelector(`#timeline-item-${index}-nav-item`);
        const animatedElements = {
          image: document.querySelector(`${elementSelector} .timeline-item-image`),
          line: document.querySelector(`${elementSelector} .line`),
          textures: document.querySelector(`${elementSelector} .timeline-item-textures`),
          body: document.querySelector(`${elementSelector} .timeline-item-content-body`),
          nextButton: document.querySelector(`${elementSelector} .next-timeline-item-button`),
        };
        const scene = new this.ScrollMagic.Scene({
          triggerElement: elementSelector, // Starting scene, when reaching this element
          triggerHook: 'onLeave', // Fixes the element to the TOP of the viewport
          duration: Math.max(document.documentElement.clientHeight, window.innerHeight || 0), // Set scroll duration for scene to height of viewport
        })
        .setPin(elementSelector) // Sets the element to pin during the scene
        .on('enter', (event) => {
          // Showing navigation
          const enteringTimeline =
            (index === 0 && event.scrollDirection === 'FORWARD') ||
            (index === timeline.length - 1 && event.scrollDirection === 'REVERSE');
          if (enteringTimeline) {
            timelineNavElement.classList.add('active');
            scrollIconElement.classList.add('active');
          }
          // Remove active class from all nav items
          const allNavItems = document.querySelectorAll('#timeline-navigation > ul > li');
          [].forEach.call(allNavItems, (navItem) => {
              navItem.classList.remove('active');
          });
          // Add active class to current scene
          navItemElement.classList.add('active');
        })
        .on('leave', (event) => {
          // Hiding navigation
          const leavingTimeline =
            (index === 0 && event.state === 'BEFORE') ||
            (index === timeline.length - 1 && event.state === 'AFTER');
          if (leavingTimeline) {
            timelineNavElement.classList.remove('active');
            scrollIconElement.classList.remove('active');
          }
        })
        .on('progress', (event) => {
          this.updateSceneElements(animatedElements, event.progress);
        });
        return scene;
      });
      if (this.controller) this.controller.addScene(scenesArray); // Adds the scenes to the controller
    }
  }

  getGridTexture = (index) => {
    const mod = index % 8;
    switch(mod) {
      case 0:
        return <ServiceSvg type="civil" fill="#3C4244" />;
      case 1:
        return <ServiceSvg type="precast" fill="#3C4244" />;
      case 2:
        return <ServiceSvg type="equipment" fill="#3C4244" />;
      case 3:
        return <ServiceSvg type="waste" fill="#3C4244" />;
      case 4:
        return <ServiceSvg type="trenchless" fill="#3C4244" />;
      case 5:
        return <ServiceSvg type="rail" fill="#3C4244" />;
      case 6:
        return <ServiceSvg type="assist" fill="#3C4244" />;
      case 7:
        return <ServiceSvg type="safety" fill="#3C4244" />;
      default:
        return null;
    }
  }

  scrollToTarget = (event, elementAnchor) => {
    if (event) event.preventDefault();
    if (typeof window !== 'undefined') {
      const targetElement = document.querySelector(elementAnchor);
      const bottomScrollPosOfTargetElement = window.pageYOffset + targetElement.getBoundingClientRect().bottom;
      scrollTo(bottomScrollPosOfTargetElement - 1, 1200);
    }
  }

  render() {
    const {
      timeline, // repeater
    } = this.props;
    if (!timeline) return null;
    return (
      <section className="timeline-block">
        <div id="timeline-navigation">
          <ul>
            {timeline.map((timelineItem, itemIndex) => {
              return (
                <li id={`timeline-item-${itemIndex}-nav-item`}>
                  {timelineItem.timelineItemDate && <span className="date">{timelineItem.timelineItemDate}</span>}
                  <span className="bullet">
                    {`Timeline Item ${itemIndex + 1}`}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="timeline-items">
          {timeline.map((timelineItem, itemIndex) => {
            const {
              image,
              timelineItemDate: date,
              timelineItemContent
            } = timelineItem;
            return (
              <div className="timeline-item" id={`timeline-item-${itemIndex}`}>
                <span className="line" />
                <div className="wrapper wide">
                  <div className="timeline-item-content">
                    <div className="timeline-item-image-container">
                      <div className="timeline-item-image">
                        <Image image={image} position="relative" />
                      </div>
                    </div>
                    <div className="timeline-item-content-body">
                      <h2 dangerouslySetInnerHTML={{__html: date}} />
                      <div className="body-text" dangerouslySetInnerHTML={{__html: timelineItemContent}} />
                    </div>
                    {itemIndex < timeline.length - 1 && (
                      <button
                        type="button"
                        className="next-timeline-item-button"
                        onClick={(event) => this.scrollToTarget(event, `#timeline-item-${itemIndex + 1}`)}
                      />
                    )}
                  </div>
                  <div className="timeline-item-textures">
                    <div className="textures">
                      {/* Recheck these extractions from icon if errors occur */}
                      {this.getGridTexture(itemIndex)}
                      {this.getGridTexture(itemIndex)}
                      {this.getGridTexture(itemIndex)}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div id='timeline-icon-scroll' />
      </section>
    );
  }
}
