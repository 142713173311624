import React, { Component } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { Carousel } from '../components/Carousel';
import Image from '../components/Image';
import lightGrid from '../images/grid-light.svg';
import { ServiceSvg } from '../components/ServiceSvg';
import { getPageSlugFromWpLink } from '../utils/helpers';
import { decodeEntities } from '../utils/htmlParse';
import './SliderBlock.scss';

export class SliderBlock extends Component {
  state = { slick: null, activeIndex: 0, marginLeft: 0 };

  componentDidMount() {
    this.getSliderOffset();
    window.addEventListener('resize', this.getSliderOffset);
  }

  componentDidUpdate(prevProps, prevState) {
    const { slider: oldSlider } = prevState;
    const { slider: newSlider } = this.state;
    if (oldSlider !== newSlider) this.getSliderOffset();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getSliderOffset);
  }

  getSliderOffset = () => {
    const wrapper = document.getElementById('slider-offset');
    const dots = document.querySelector('.slider-block .slick-dots');
    if (!wrapper) return null;
    const { offsetLeft } = wrapper;
    const paddingLeftStr = window.getComputedStyle(wrapper, null).getPropertyValue('padding-left');
    const paddingLeftInt = paddingLeftStr ? paddingLeftStr.replace('px', '') : 0;
    this.setState({ marginLeft: offsetLeft + parseInt(paddingLeftInt, 10) });
    if (dots) {
      dots.style.right = `${offsetLeft}px`;
    }
    return null;
  }

  getSlick = (slick) => {
    const { slick: stateSlickObj } = this.state;
    if (!stateSlickObj && slick) {
      this.setState({ slick });
    }
  }

  goToSlide = (event, activeIndex) => {
    const { slick } = this.state;
    if (slick && activeIndex !== null) {
      if (activeIndex < slick.innerSlider.state.currentSlide) {
        slick.slickGoTo(slick.innerSlider.state.slideCount + activeIndex);
      }
      slick.slickGoTo(activeIndex);
    }
  }

  beforeChange = (oldIndex, activeIndex) => {
    this.setState({ activeIndex });
  }

  render() {
    const {
      title,
      content,
      image,
      hasButton,
      buttonText,
      buttonLink,
      slides,
      useServices,
      isOnHomepage,
      wordpressUrl
    } = this.props;
    const { activeIndex, marginLeft } = this.state;
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                servicesSlug
              }
            }
            services: allWordpressWpServices(sort: {fields: date, order: ASC}) {
              edges {
                node {
                  title
                  slug
                  image: featured_media {
                    localFile {
                      childImageSharp {
                        fixed(width: 374, height:442) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                  acf {
                    shortDescription
                  }
                }
              }
            }
          }
        `}
        render={({ services, site: { siteMetadata: { servicesSlug } } }) => {
          return (
            <section className="slider-block">
              <div className="wrapper slider-block-content-wrapper">
                <div className="slider-block-content" id="slider-offset">
                  <h2>{decodeEntities(title)}</h2>
                  <div className="slider-block-content-body" dangerouslySetInnerHTML={{ __html: content }} />
                  {hasButton && <Link className="button dark" to={getPageSlugFromWpLink(buttonLink, wordpressUrl)}>{decodeEntities(buttonText) || `Learn more`}</Link>}
                </div>
                <div className="slider-block-image">
                  <Image image={image} />
                </div>
              </div>
              <section className="slider-block-slides" style={{ marginLeft }}>
                <Carousel slidesToShow={1} slidesToScroll={1} getSlick={this.getSlick} beforeChange={this.beforeChange} infinite variableWidth>
                  {/* If 'Use as Services Slider' checkbox is ticked, use services as slides */}
                  {useServices ? services && services.edges && services.edges.length > 0 && services.edges.map(({node: service}, serviceIndex) => {
                    const { title: serviceTitle, slug: serviceSlug, image: serviceImage, acf = {} } = service;
                    const { shortDescription = null } = acf;
                    const isActive = activeIndex === serviceIndex;
                    return (
                      // eslint-disable-next-line
                      <div className={`slider-block-slide no-icon ${isActive ? 'active' : ''}`} key={serviceSlug} onClick={event => this.goToSlide(event, serviceIndex)}>
                        <div className="slider-block-slide-content">
                          <Image image={serviceImage} />
                          <div className="slider-block-slide-content-overlay">
                            <h3>{decodeEntities(serviceTitle)}</h3>
                            <p>{shortDescription}</p>
                          </div>
                        </div>
                        <div className="slider-block-slide-expanded">
                          <Link to={`/${servicesSlug}/${serviceSlug}/`} className="button dark">Learn more</Link>
                        </div>
                      </div>
                    );
                  }) : slides.map((slide, index) => {
                    const { slideTitle, slideSubtitle, slideImage, slideContent, slideButtonLink, slideButtonText, icon } = slide;
                    const isActive = activeIndex === index;
                    return (
                      // eslint-disable-next-line
                      <div className={`${isActive ? 'slider-block-slide active' : 'slider-block-slide'} ${icon === 'none' ? 'no-icon' : ''}`} key={index} onClick={event => this.goToSlide(event, index)}>
                        <div className="slider-block-slide-content">
                          <Image image={slideImage} />
                          <div className="slider-block-slide-content-overlay">
                            {icon !== 'none' && <ServiceSvg type={icon} fill="#51585A" />}
                            <h3>{decodeEntities(slideTitle)}</h3>
                            <p>{slideSubtitle}</p>
                          </div>
                        </div>
                        {(slideContent || slideButtonLink) && (
                          <div className="slider-block-slide-expanded">
                            <p>{slideContent}</p>
                            {slideButtonLink && <Link to={getPageSlugFromWpLink(slideButtonLink, wordpressUrl)} className="button dark">{decodeEntities(slideButtonText) || 'More Information'}</Link>}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </Carousel>
              </section>
              {!isOnHomepage && (
                <img src={lightGrid} className="grid-texture" alt="One group, many services" />
              )}
            </section>
          );
        }}
      />
    );
  }
}
