import React, { Component } from 'react';
import './SearchForm.scss';

export class SearchForm extends Component {
  render() {
    const {
      onSubmit,
      onChange
    } = this.props;
    return (
      <form onSubmit={onSubmit} className="search-form">
        <label htmlFor="search-form-input">
          <input
            placeholder="Search"
            type="text"
            id="search-form-input"
            name="Search form input"
            onChange={onChange}
          />
        </label>
        <button type="submit" id="search-form-button">Submit</button>
      </form>
    );
  }
}
