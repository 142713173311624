import React from 'react';
import Img from 'gatsby-image';
import Image from '../components/Image';
import './Infographic.scss';

export const Infographic = (props) => {
  const {
    image
  } = props;
  return (
    <section className="infographic">
      <div className="wrapper infographic-wrapper">
        <div className="image-container">
          <Image image={image} position="relative" />
        </div>
      </div>
    </section>
  );
}
