import React from 'react'
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import grid from '../images/grid.svg';
import { HeaderLogo } from '../components/HeaderLogo';
import './404.scss';
import '../templates/project.scss';

const NotFoundPage = () => (
  <Layout className="four-oh-four-page">
    <SEO title="Page Not Found" />
    <header className="project-header">
      <HeaderLogo />
      <img src={grid} className="grid-texture" alt="One group, many services" />
      <img src={grid} className="grid-texture" alt="One group, many services" />
    </header>
    <section className="four-oh-four-message">
      <div className="wrapper">
        <h1>The page you were looking for doesn’t exist.</h1>
        <p>The page you were looking for doesn’t exist. You may have mistyped the address or the page may have moved.</p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
