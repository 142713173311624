import React from 'react';
import { Link } from 'gatsby';
import Image from '../components/Image';
import grid from '../images/grid.svg';
import { getPageSlugFromWpLink } from '../utils/helpers';
import './Cta.scss';

export const Cta = (props) => {
  const {
    backgroundColor,
    title,
    content,
    image,
    buttonText,
    buttonLink,
    wordpressUrl,
    isLastComponent
  } = props;
  return (
    <section className={`cta-block ${backgroundColor}`}>
      <div className="wrapper">
        <div className="cta-container">
          <h2 dangerouslySetInnerHTML={{__html: title}} />
          {content && <div className="content" dangerouslySetInnerHTML={{__html: content}} />}
          <Link className="button" to={getPageSlugFromWpLink(buttonLink, wordpressUrl)}>{buttonText}</Link>
        </div>
        <Image image={image} position="relative" />
      </div>
      {isLastComponent && (
        <div className="bottom-separator">
          <div className="wrapper">
            <img src={grid} className="grid-texture" alt="One group, many services" />
          </div>
        </div>
      )}
    </section>
  )
}
