import React, { Component } from 'react';
import './Filter.scss';

export class Filter extends Component {
  state = {
    selectedOptionLabel: null,
    selectedOptionIndex: null,
    filterOpen: false
  }

  onOptionSelect = (option, optionIndex, all) => {
    const { onChange } = this.props;
    if (all) {
      onChange(null);
    } else {
      onChange(option.val);
    }
    this.setState({
      selectedOptionLabel: all ? null : option.label,
      selectedOptionIndex: all ? null : optionIndex,
      filterOpen: false
    });
  }

  toggleFilter = () => {
    const { filterOpen } = this.state;
    this.setState({ filterOpen: !filterOpen });
  }

  render() {
    const {
      options = []
    } = this.props;
    const {
      selectedOptionLabel,
      selectedOptionIndex,
      filterOpen
    } = this.state;
    return (
      <div className={`filter ${filterOpen ? 'active' : ''}`}>
        <div className="selected-option" onClick={this.toggleFilter}> {/* eslint-disable-line */}
          <span>{`Filter by${selectedOptionLabel ? `: ${selectedOptionLabel}` : ''}`}</span>
        </div>
        <ul className="options">
          {/* eslint-disable-next-line */}
          <li
            className="option"
            onClick={() => this.onOptionSelect(null, null, true)}
          >
            All
          </li>
          {options.map((option, optionIndex) => {
            return (
              // eslint-disable-next-line
              <li
                className={`option ${selectedOptionIndex === optionIndex ? 'selected' : ''}`}
                onClick={() => this.onOptionSelect(option, optionIndex)}
                key={option.val}
              >
                {option.label}
              </li>
            )
          })}
        </ul>
      </div>
    );
  }
}
