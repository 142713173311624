import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { decodeEntities } from '../utils/htmlParse';
import Layout from '../components/Layout';
import * as AcfLayout from '../acf';
import SEO from '../components/SEO';
import Previewable from '../components/Previewable';
import './page.scss';

const AcfComponent = ({ location, componentName, item, isOnHomepage, wordpressUrl, isLastComponent }) => {
  const ComponentName = AcfLayout[componentName];
  return (
    <ComponentName
      location={location}
      wordpressUrl={wordpressUrl}
      isOnHomepage={isOnHomepage}
      isLastComponent={isLastComponent}
      {...item}
    />
  );
};

export const PageTemplate = ({ title, content }) => {
  return (
    <section className="page">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="section">
              <h2 className="title">
                {title}
              </h2>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const Page = ({ data, location }) => {
  const { wordpressPage: page, site } = data;
  if (!page) return null;
  const { title, content, slug, yoast, acf = {} } = page;
  if (!acf) return null;
  const { layout } = acf;
  const { title: siteTitle, wordpressUrl } = site.siteMetadata;
  const isHome = !location.pathname || location.pathname === '/';
  return (
    <Layout location={location} className={`page--${slug}`}>
      <SEO
        title={`${decodeEntities(yoast.metaTitle) || decodeEntities(title)} | ${decodeEntities(siteTitle)}`}
        desc={yoast.metaDescription}
      />
      {layout ? layout.map((item, index) => {
        if (!item.__typename) return null;
        const layoutComponentName = item.__typename.replace('WordPressAcf_','');
        return (
          <AcfComponent
            key={index}
            componentName={layoutComponentName}
            item={item}
            location={location}
            wordpressUrl={wordpressUrl}
            isOnHomepage={isHome}
            isLastComponent={index === (layout.length - 1)}
          />
        );
      }) : (
        <PageTemplate title={title} content={content} />
      )}
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Previewable(Page)

export const pageQuery = graphql`
  query PageById($id: String!) {
    site {
      siteMetadata {
        title
        wordpressUrl
        functionsUrl
      }
    }
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
      acf {
        layout: layout_page {
          __typename
          ... on WordPressAcf_Header {
          	headerContent: content {
              backgroundImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              titleFirstLine
              titleSecondLine
              content
              buttonText
              buttonLink
            }
          }
          ... on WordPressAcf_IntroBlock {
            backgroundColor
            title
            content
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 530, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            layout
            buttonText
            buttonLink
          }
          ... on WordPressAcf_SliderBlock {
            id,
            title,
            content,
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 640, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            },
            hasButton
            buttonText
            buttonLink
            useServices
            slides {
              slideTitle
              slideSubtitle
              slideImage {
                localFile {
                  childImageSharp {
                    fixed(width: 374, height:472) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              },
              slideContent
              slideButtonText
              slideButtonLink
              icon
            }
          }
          ... on WordPressAcf_GridBlock {
            title
            gridItems {
              useIcon
              icon {
                source_url
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              title
              subtitle
              content
            }
          }
          ... on WordPressAcf_RecentProjects {
            title
            subtitle
            content
          }
          ... on WordPressAcf_Logos {
            title
            logos {
              name
              logo {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 220) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_Cta {
            backgroundColor
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 680, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            title
            content
            buttonText
            buttonLink
          }
          ... on WordPressAcf_ImageWithContent {
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            title
            layout
            content
            hasButton
            buttonText
            buttonLink
            hasColumnText
            columns {
              title
              content
            }
          }
          ... on WordPressAcf_PlainText {
            content
          }
          ... on WordPressAcf_List {
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            title
            subtitle
            list {
              item
            }
          }
          ... on WordPressAcf_DescriptiveImage {
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            title
            content
          }
          ... on WordPressAcf_Infographic {
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WordPressAcf_Accordion {
            intro
            contentType
            accordionItems {
              icon
              title
              content
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              buttonText
              buttonLink
            }
          }
          ... on WordPressAcf_Locations {
            title
          }
          ... on WordPressAcf_FormBlock {
            title
            subtitle
            form
          }
          ... on WordPressAcf_Services {
            backgroundImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            titleFirstLine
            titleSecondLine
            content
          }
          ... on WordPressAcf_Timeline {
            timeline {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              timelineItemDate
              timelineItemContent
            }
          }
        }
      }
    }
  }
`
