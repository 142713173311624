import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Carousel } from '../components/Carousel';
import Image from '../components/Image';
import HomePageImageQuery from '../components/Image/HomePageImageQuery'
import { HeaderLogo } from '../components/HeaderLogo';
import { getPageSlugFromWpLink } from '../utils/helpers';
import grid from '../images/grid.svg';
import './Header.scss';

export class Header extends Component {
  render() {
    const { headerContent: sliderArr, wordpressUrl, location } = this.props;
    return (
      <header className="main-header">
        <HeaderLogo />
        <Carousel unslick={sliderArr.length < 2} arrows={false}>
          {sliderArr.map((slide, index) => {
            const { backgroundImage, titleFirstLine, titleSecondLine, content, buttonText, buttonLink } = slide;
            const HeaderTag = index === 0 ? 'h1' : 'h2';
            return (
              <div className="main-header-container" key={titleFirstLine}>
                {location.pathname === "/" && index === 0 ? <HomePageImageQuery className="background" objectPosition="top center"/> : <Image image={backgroundImage} objectPosition="top center" />}
                <div className="wrapper">
                  <div className="main-header-content">
                    <HeaderTag>
                      {titleFirstLine}
                      <span>{titleSecondLine}</span>
                    </HeaderTag>
                    <div className="main-header-content-body" dangerouslySetInnerHTML={{ __html: content }} />
                    {buttonLink && <Link to={getPageSlugFromWpLink(buttonLink, wordpressUrl)} className="button">{buttonText || 'More Information'}</Link>}
                  </div>
                </div>
                <img src={grid} className="grid-texture" alt="One group, many services" />
              </div>
            );
          })}
        </Carousel>
      </header>
    );
  }
}
