import React from 'react';
import Helmet from 'react-helmet';
import TopNavigation from './TopNavigation';
import { Footer } from './Footer';
import '../sass/global/styles.scss';
import './Layout.scss';
import FontUrl1 from "../fonts/1570205/d18c3260-0c75-4ac2-9c0c-aa452042c5a4.woff2"

const TemplateWrapper = ({ children, className = '', location }) => (
  <div id="layout" className={className}>
    <Helmet>
      <title>Home | Gatsby + WordPress</title>
      <meta name="format-detection" content="telephone=no" />
      <link rel="preload" as="font" href={FontUrl1} type="font/woff2" crossOrigin="anonymous" />
    </Helmet>
    <TopNavigation location={location} />
    <main>{children}</main>
    <Footer location={location} />
  </div>
)

export default TemplateWrapper
