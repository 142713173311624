import React from 'react';
import Image from '../components/Image';
import grid from '../images/grid-light.svg';
import './List.scss';

export const List = props => {
  const {
    image,
    title,
    subtitle,
    list
  } = props;
  return (
    <section className="list-block">
      <div className="wrapper list-block-wrapper">
        <div className="list-block-image-container">
          <Image image={image} position="relative" />
        </div>
        <div className="list-block-content">
          <h2 dangerouslySetInnerHTML={{__html: title}} />
          <span className="subtitle" dangerouslySetInnerHTML={{__html: subtitle}} />
          <ul className="list">
            {list.map(item => (
              <li className="list-item">{item.item}</li>
            ))}
          </ul>
        </div>
        <img className="grid-texture" src={grid} alt="List Block texture" />
      </div>
    </section>
  );
}
