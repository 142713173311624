import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { decodeEntities } from '../../utils/htmlParse';
import './Contact.scss';

export class Contact extends Component {
  constructor(props) {
    super(props);
    this.nameError = React.createRef();
    this.phoneError = React.createRef();
    this.projectLocationError = React.createRef();
    this.emailError = React.createRef();
    this.serviceError = React.createRef();
    this.state = {
      allFieldsValid: false
    }
  }

  componentDidMount() {
    document.getElementById('contact-name').setCustomValidity('This field is mandatory');
    document.getElementById('contact-phone').setCustomValidity('This field is mandatory');
    document.getElementById('project-location').setCustomValidity('This field is mandatory');
    document.getElementById('contact-email').setCustomValidity('Please enter a valid email address');
    document.getElementById('contact-service').setCustomValidity('Please select a service type');
  }

  validateName = () => {
    const nameField = document.getElementById('contact-name');
    if (nameField.value === '') {
      nameField.setCustomValidity('This field is mandatory');
      nameField.classList.add('error');
    } else {
      nameField.setCustomValidity('');
      nameField.classList.remove('error');
    }
    this.allFieldsValid();
    this.nameError.current.innerHTML = nameField.validationMessage;
  }

  validatePhone = () => {
    const phoneField = document.getElementById('contact-phone');
    if (phoneField.value === '') {
      phoneField.setCustomValidity('This field is mandatory');
      phoneField.classList.add('error');
    } else {
      phoneField.setCustomValidity('');
      phoneField.classList.remove('error');
    }
    this.allFieldsValid();
    this.phoneError.current.innerHTML = phoneField.validationMessage;
  }

  validateEmail = () => {
    const emailField = document.getElementById('contact-email');
    if (emailField.value === '') {
      emailField.setCustomValidity('Please enter a valid email address');
      emailField.classList.add('error');
    } else {
      emailField.setCustomValidity('');
      emailField.classList.remove('error');
    }
    this.allFieldsValid();
    this.emailError.current.innerHTML = emailField.validationMessage;
  }

  validateProjectLocation = () => {
    const projectLocationField = document.getElementById('project-location');
    if (projectLocationField.value === '') {
      projectLocationField.setCustomValidity('This field is mandatory');
      projectLocationField.classList.add('error');
    } else {
      projectLocationField.setCustomValidity('');
      projectLocationField.classList.remove('error');
    }
    this.allFieldsValid();
    this.projectLocationError.current.innerHTML = projectLocationField.validationMessage;
  }

  validateService = () => {
    const serviceField = document.getElementById('contact-service');
    if (serviceField.value === '') {
      serviceField.setCustomValidity('This field is mandatory');
      serviceField.classList.add('error');
    } else {
      serviceField.setCustomValidity('');
      serviceField.classList.remove('error');
    }
    this.allFieldsValid();
    this.serviceError.current.innerHTML = serviceField.validationMessage;
  }

  onClick = () => {
    this.validateName();
    this.validatePhone();
    this.validateEmail();
    this.validateProjectLocation();
    this.validateService();
  }

  allFieldsValid = () => {
    const fields = document.querySelectorAll('label input, label select, label textarea');
    const allValid = Array.from(fields).every(field => field.validity.valid);
    if (allValid) {
      this.setState({ allFieldsValid: true });
    } else {
      this.setState({ allFieldsValid: false });
    }
    return Array.from(fields).every(field => field.validity.valid);
  }

  render() {
    const { location } = this.props;
    const { allFieldsValid } = this.state;
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                siteUrl
              }
            }
            allWordpressWpServices {
              edges {
                node {
                  title
                  acf {
                    shortTitle
                  }
                }
              }
            }
          }
        `}
        render={(data) => (
          <form
            className="contact-form"
            method="post"
            action="https://api.formbucket.com/f/buk_mUJ9etmpJNIl0Oz90BGCkW23"
            // onSubmit={this.onSubmit}
          >
            <input name="thank_you_url" value={`${data.site.siteMetadata.siteUrl}${location.pathname}?success=true`} type="hidden" />
            <label htmlFor="contact-name">
              <span className="error-message" ref={this.nameError} />
              <input
                type="text"
                name="name"
                id="contact-name"
                placeholder="Name"
                className="name"
                onBlur={this.validateName}
                required
              />
              <span>Name</span>
            </label>
            <label htmlFor="contact-phone">
              <span className="error-message" ref={this.phoneError} />
              <input
                type="text"
                name="phone"
                id="contact-phone"
                placeholder="Phone"
                className="phone"
                onBlur={this.validatePhone}
                required
              />
              <span>Phone</span>
            </label>
            <label htmlFor="project-location">
              <span className="error-message" ref={this.projectLocationError} />
              <input
                type="text"
                name="location"
                id="project-location"
                placeholder="Project Location"
                className="location"
                onBlur={this.validateProjectLocation}
                required
              />
              <span>Project Location</span>
            </label>
            <label htmlFor="email">
              <span className="error-message" ref={this.emailError} />
              <input
                type="email"
                id="contact-email"
                name="email"
                placeholder="Email"
                className="email"
                onBlur={this.validateEmail}
                required
              />
              <span>Email</span>
            </label>
            <label htmlFor="contact-service">
              <span className="error-message" ref={this.serviceError} />
              <select id="contact-service" name="service" onChange={this.validateService} required>
                <option selected disabled value="">Service</option>
                { data.allWordpressWpServices &&
                  data.allWordpressWpServices.edges &&
                  data.allWordpressWpServices.edges.map(({node: service}) => (
                    <option value={decodeEntities(service.title)}>{decodeEntities(service.title)}</option>
                  ))}
              </select>
              <span>Service</span>
            </label>
            <label htmlFor="contact-message">
              <textarea
                className="message"
                id="contact-message"
                rows="5"
                name="message"
                placeholder="Your message"
              />
              <span>Your Message</span>
            </label>
            {/* Submit button */}
            {allFieldsValid ?
              <button className="button" onClick={this.onClick} type="submit" aria-label="Send">Send</button> :
              <button className="button" onClick={this.onClick} type="button" aria-label="Send">Send</button>
            }
          </form>
        )}
      />
    );
  }
}
