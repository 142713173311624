import React, { Component } from 'react';
import Image from '../components/Image';
import './GridBlock.scss';

const GridBlockItem = (props) => {
  const { useIcon, icon, image, title, subtitle, content } = props;
  return (
    <div className={`grid-block-item ${useIcon ? 'with-icon' : 'with-image'}`}>
      <div className="grid-block-icon">
        <Image image={image || {url: icon.source_url}} alt={icon ? title : null}  />
      </div>
      <div className="grid-block-item-content">
        <span className="grid-block-item-title">{title}</span>
        <span className="grid-block-item-subtitle">{subtitle}</span>
        <p className="grid-block-item-paragraph" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  )
}

export class GridBlock extends Component {
  render() {
    const { title, gridItems } = this.props;
    return (
      <section className="grid-block">
        <div className="wrapper">
          <h3>{title}</h3>
          <div className="grid-block-items">
            {gridItems.map(item => <GridBlockItem key={item.title} {...item} />)}
          </div>
        </div>
      </section>
    );
  }
}
