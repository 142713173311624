import React, { Component } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Image from './Image';
import grid from '../images/grid.svg';
import { HeaderLogo } from './HeaderLogo';
import { SearchForm } from './SearchForm';
import { Filter } from './Filter';
import { getExcerpt } from '../utils/htmlParse';
import '../acf/Header.scss';
import './BlogFeed.scss';

class BlogFeed extends Component {
  constructor(props) {
    super(props);
    const {
      posts,
      projects,
      loadMoreLimit
    } = props;
    const feedItems = [...posts, ...projects];
    // const sortedFeedItems = feedItems.sort((a, b) => {
    //   const dateA = new Date(a.node.date);
    //   const dateB = new Date(b.node.date);
    //   return dateA.getTime() < dateB.getTime() ? 1 : -1;
    // });
    this.state = {
      searchQuery: '',
      allItems: feedItems,
      activeItems: feedItems,
      activeFilter: null,
      numItemsToDisplay: loadMoreLimit
    };
  }

  onSearchFormChange = (e) => {
    const { allItems } = this.state;
    this.setState({ searchQuery: e.target.value });
    if (e.target.value === '') this.setState({ activeItems: allItems });
  }

  onSearchFormSubmit = (e) => {
    const { loadMoreLimit } = this.props;
    e.preventDefault();
    // Filter items
    const { searchQuery, allItems = null } = this.state;
    const filteredItems = allItems ? allItems.filter(({ node: item }) => {
      return item.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
    }) : null;
    this.setState({
      activeItems: filteredItems,
      numItemsToDisplay: loadMoreLimit
    });
  }

  getFilterOptions = () => {
    const { categories } = this.props;
    const filterOptions = categories.map(({node: cat}) => {
      return {
        val: cat.slug,
        label: cat.name,
      };
    });
    return filterOptions;
  }

  onFilterChange = (filterOption) => {
    const { loadMoreLimit } = this.props;
    return this.setState({
      activeFilter: filterOption,
      numItemsToDisplay: loadMoreLimit
    });
  }

  loadMoreItems = () => {
    const { numItemsToDisplay } = this.state;
    const { loadMoreLimit } = this.props;
    return this.setState({ numItemsToDisplay: numItemsToDisplay + loadMoreLimit });
  }

  render() {
    const {
      data = null
    } = this.props;
    const {
      wordpressAcfOptions: {
        options
      },
      site: {
        siteMetadata: {
          blogSlug
        }
      }
    } = data;
    const { blogHeaderImage, blogTitleFirstLine, blogTitleSecondLine, blogHeaderContent } = options;
    const { activeItems, activeFilter, numItemsToDisplay } = this.state;
    // Getting posts related to category filter selected
    // activeItems are already filtered based on the search query (if a search query is entered)
    const itemsToDisplay = activeFilter ?
      activeItems.filter(({node: item}) => {
        return item.categories.map(cat => cat.slug).includes(activeFilter);
      }) :
      activeItems;
    // Limiting number of posts to show based on constant defined in blog template (templates/blog.js)
    const visibleItems = itemsToDisplay.slice(0,numItemsToDisplay);
    return (
      <section className="blog-feed">
        <div className="main-header">
          <HeaderLogo />
          <div className="main-header-container">
            <Image image={blogHeaderImage} />
            <div className="wrapper">
              <div className="main-header-content">
                <h1>
                  {blogTitleFirstLine}
                  <span>{blogTitleSecondLine}</span>
                </h1>
                <div className="main-header-content-body" dangerouslySetInnerHTML={{ __html: blogHeaderContent }} />
                <SearchForm
                  onChange={this.onSearchFormChange}
                  onSubmit={this.onSearchFormSubmit}
                />
                <Filter
                  options={this.getFilterOptions()}
                  onChange={this.onFilterChange}
                />
              </div>
            </div>
            <img src={grid} className="grid-texture" alt="One group, many services" />
          </div>
        </div>
        {visibleItems.length > 0 ? (
          <div className="feed-items">
            <div className="wrapper">
              {visibleItems.map(({node: item}) => {
                const { title, featuredImage, slug } = item;
                return (
                  <Link to={`/${blogSlug}/${slug}/`} className="feed-item" key={`${title}--${slug}`}>
                    <div className="featured-image">
                      <Image image={featuredImage} position="absolute" />
                    </div>
                    <h2 dangerouslySetInnerHTML={{__html: getExcerpt(title, 50)}} />
                  </Link>
                );
              })}
              {itemsToDisplay.length > numItemsToDisplay && (
                // eslint-disable-next-line
                <div className="feed-item load-more" onClick={this.loadMoreItems}>
                  <span className="load-more-plus" />
                  <span>Load more</span>
                </div>
              )}
            </div>
          </div>
        ) : <div className="no-results"><div className="wrapper">{'Sorry, we couldn\'t find any matching results.'}</div></div>}
      </section>
    );
  }
}

export default (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              blogSlug
            }
          }
          wordpressAcfOptions {
            options {
              blogHeaderImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              blogTitleFirstLine
              blogTitleSecondLine
              blogHeaderContent
            }
          }
        }
      `}
      render={(data) => <BlogFeed data={data} {...props} />}
    />
  );
}
