import React from 'react';
import { graphql, Link } from 'gatsby';
import grid from '../images/grid.svg';
import lightGrid from '../images/grid-light.svg';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Image from '../components/Image';
import { HeaderLogo } from '../components/HeaderLogo';
import { SocialLink } from '../components/SocialLink';
import Previewable from '../components/Previewable';
import { RecentProjects } from '../acf/RecentProjects';
import { decodeEntities } from '../utils/htmlParse';
import './project.scss';
import './post.scss';
import '../acf/RecentProjects.scss';

const Post = ({ data, location, pageContext }) => {
  const { site, wordpressPost: post, allCategories, relatedPosts } = data;
  if (!post) return null;
  const { siteMetadata: { siteTitle, blogSlug, siteUrl } } = site;
  const { previous: previousPost, next: nextPost } = pageContext;
  const { title, date, content, categories = [], featuredImage, yoast, acf = {} } = post;
  const { postIntro } = acf;
  const postCategory = categories.length > 0 ?
    allCategories.edges.find(({node: category}) => category.wordpress_id === (categories[0].wordpress_id || categories[0].id)).node.name :
    null;
  return (
    <Layout location={location} className="post-template">
      <SEO
        title={`${decodeEntities(yoast.metaTitle) || decodeEntities(title)} | ${decodeEntities(siteTitle)}`}
        desc={yoast.metaDescription}
      />
      <header className="project-header">
        <HeaderLogo />
        <img src={grid} className="grid-texture" alt="One group, many services" />
        <img src={grid} className="grid-texture" alt="One group, many services" />
      </header>
      <section className="project-content">
        <div className="project-content-top">
          <div className="wide-wrapper">
            <div className="wrapper">
              <div className="project-intro">
                <div>
                  <span className="date" dangerouslySetInnerHTML={{__html: date}} />
                  <span className="category" dangerouslySetInnerHTML={{__html: postCategory || ''}} />
                </div>
                <h1 dangerouslySetInnerHTML={{__html: title}} />
                <span className="subtitle" dangerouslySetInnerHTML={{__html: postIntro}} />
              </div>
            </div>
            <div className="share-buttons">
              <span>Share</span>
              <SocialLink
                socialLink={{type: 'facebook', url: `https://www.facebook.com/sharer/sharer.php?u=${location.href}`}}
                theme="dark"
                size="smaller"
              />
              <SocialLink
                socialLink={{type: 'twitter', url: `https://twitter.com/intent/tweet/?text=${`${post.title} - `}&url=${location.href}`}}
                theme="dark"
                size="smaller"
              />
              <SocialLink
                socialLink={{type: 'linkedin', url: `https://www.linkedin.com/shareArticle?mini=true&url=${location.href}&title=${post.title}&summary=&source=${siteUrl}`}}
                theme="dark"
                size="smaller"
              />
            </div>
            <div className="featured-image">
              <Image image={featuredImage} />
            </div>
          </div>
        </div>
        <div className="project-body">
          <div className="wide-wrapper">
            <div className="wrapper">
              <div className="content-container">
                <div className="content" dangerouslySetInnerHTML={{__html: content}} />
              </div>
              <div className="project-details" />
            </div>
            <img src={lightGrid} className="grid-texture" alt="Project body texture" />
          </div>
        </div>
        {(previousPost || nextPost) && (
          <div className="project-navigation">
            <div className="wrapper">
              <div className="previous">
                {previousPost && (
                  <Link to={`/${blogSlug}/${previousPost.slug}/`}>
                    <span>Prev Post</span>
                    <span>{decodeEntities(previousPost.title)}</span>
                  </Link>
                )}
              </div>
              <div className="next">
                {nextPost && (
                  <Link to={`/${blogSlug}/${nextPost.slug}/`}>
                    <span>Next Post</span>
                    <span>{decodeEntities(nextPost.title)}</span>
                  </Link>
                )}
              </div>
            </div>
          </div>
        )}
      </section>
      {relatedPosts && (
        <RecentProjects
          projects={relatedPosts.edges}
          title="Related"
          subtitle="Posts"
        />
      )}
    </Layout>
  );
}

export default Previewable(Post);

export const pageQuery = graphql`
  query PostById($id: String!, $categories: [Int!]) {
    site {
      siteMetadata {
        siteTitle: title
        blogSlug
        siteUrl
        wordpressUrl
        functionsUrl
      }
    }
    allCategories: allWordpressCategory {
      edges {
        node {
          wordpress_id
          name
        }
      }
    }
    wordpressPost(id: { eq: $id }) {
      title
      date(formatString: "DD MMM YYYY")
      categories {
        wordpress_id
        name
      }
      content
      featuredImage: featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      acf {
        postIntro
      }
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
    }
    relatedPosts: allWordpressPost(
      filter: {
        categories: { elemMatch: { wordpress_id: { in: $categories } } },
        slug: { ne: "build-post" }
        id: { ne: $id }
      },
      limit: 9
    ) {
      edges {
        node {
          id,
          title
          content
          slug
          image: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          categories {
            wordpress_id
            name
          }
          date
          acf {
            postIntro
          }
        }
      }
    }
  }
`;
