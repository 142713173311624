import React, { Component } from 'react';
import { Link } from 'gatsby';
import Image from '../components/Image';
import { getPageSlugFromWpLink } from '../utils/helpers';
import { ServiceSvg } from '../components/ServiceSvg';
import './Accordion.scss';

const AccordionItem = ({item, contentType, wordpressUrl, handleToggle, isActive, index}) => {
  return (
    <div className={`accordion-item ${contentType} ${isActive ? 'active' : ''}`}>
      <div className="accordion-title" onClick={() => handleToggle(index)}>
        <div className="title">
          <ServiceSvg type={item.icon} fill="#51585A" />
          <h2 dangerouslySetInnerHTML={{__html: item.title}} />
        </div>
      </div>
      <div className="accordion-content">
        <div className="body-container">
          <div className="body-text" dangerouslySetInnerHTML={{__html: item.content}} />
          {/* contentType = cta */}
          {(item.buttonLink && contentType === 'cta') && (
            <Link className="button" to={getPageSlugFromWpLink(item.buttonLink, wordpressUrl)}>{item.buttonText || "Find out more"}</Link>
          )}
        </div>
        {/* contentType = image */}
        {(item.image && contentType === 'image') && <Image image={item.image} position="relative" />}
      </div>
    </div>
  );
}

export class Accordion extends Component {
  state = {
    activeItem: null
  }

  handleToggle = (index) => {
    const { activeItem } = this.state;
    if ((activeItem !== null) && (activeItem === index)) {
      this.setState({activeItem: null});
    } else {
      this.setState({activeItem: index});
    }
  }

  render() {
    const {
      intro,
      contentType,
      accordionItems,
      wordpressUrl
    } = this.props;
    const { activeItem } = this.state;
    return (
      <section className="accordion">
        <div className="wrapper">
          {intro && <div className="intro" dangerouslySetInnerHTML={{__html: intro}} />}
          <div className="accordion-items">
            {accordionItems.map((item, index) => {
              return (
                <AccordionItem
                  isActive={activeItem === index}
                  item={item}
                  index={index}
                  contentType={contentType}
                  wordpressUrl={wordpressUrl}
                  handleToggle={this.handleToggle}
                />
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}
