import React from 'react';
import { graphql, Link } from 'gatsby';
import grid from '../images/grid.svg';
// import lightGrid from '../images/grid-light.svg';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { Carousel } from '../components/Carousel';
import { HeaderLogo } from '../components/HeaderLogo';
import { SocialLink } from '../components/SocialLink';
import Image from '../components/Image';
import Previewable from '../components/Previewable';
import { RecentProjects } from '../acf/RecentProjects';
import { Cta } from '../acf/Cta';
import { decodeEntities } from '../utils/htmlParse';
import './project.scss';
import '../acf/RecentProjects.scss';

const Project = ({ data, location, pageContext }) => {
  const { site, wordpressWpProjects: project, relatedProjects, contactCtaImage } = data;
  if (!project) return null;
  const { siteMetadata: { siteTitle, blogSlug, siteUrl, wordpressUrl } } = site;
  const { previous: previousPost, next: nextPost } = pageContext;
  const {
    title,
    content,
    categories,
    featuredImage,
    yoast,
    acf = {}
  } = project;
  const {
    projectIntro,
    projectCost,
    projectClient,
    projectLocation,
    projectDuration,
    projectStartDate,
    projectFinishDate,
    projectImageSlider = []
  } = acf;
  const mainCategoryName = categories[0].name;
  return (
    <Layout location={location}>
      <SEO
        title={`${decodeEntities(yoast.metaTitle) || decodeEntities(title)} | ${decodeEntities(siteTitle)}`}
        desc={yoast.metaDescription}
      />
      <header className="project-header">
        <HeaderLogo />
        <img src={grid} className="grid-texture" alt="One group, many services" />
        <img src={grid} className="grid-texture" alt="One group, many services" />
      </header>
      <section className="project-content">
        <div className="project-content-top">
          <div className="wide-wrapper">
            <div className="wrapper">
              <div className="project-intro">
                <div>
                  {/* <span className="date" dangerouslySetInnerHTML={{__html: date}} /> */}
                  <span className="category" dangerouslySetInnerHTML={{__html: mainCategoryName || ''}} />
                </div>
                <h1 dangerouslySetInnerHTML={{__html: title}} />
                <span className="subtitle" dangerouslySetInnerHTML={{__html: projectIntro}} />
              </div>
            </div>
            <div className="share-buttons">
              <span>Share</span>
              <SocialLink
                socialLink={{type: 'facebook', url: `https://www.facebook.com/sharer/sharer.php?u=${location.href}`}}
                theme="dark"
                size="smaller"
              />
              <SocialLink
                socialLink={{type: 'twitter', url: `https://twitter.com/intent/tweet/?text=${`${project.title} - `}&url=${location.href}`}}
                theme="dark"
                size="smaller"
              />
              <SocialLink
                socialLink={{type: 'linkedin', url: `https://www.linkedin.com/shareArticle?mini=true&url=${location.href}&title=${project.title}&summary=&source=${siteUrl}`}}
                theme="dark"
                size="smaller"
              />
            </div>
            <div className="featured-image">
              <Image image={featuredImage} />
            </div>
          </div>
        </div>
        <div className="project-body">
          <div className="wide-wrapper">
            <div className="wrapper">
              <div className="content-container">
                <div className="content" dangerouslySetInnerHTML={{__html: content}} />
              </div>
              <div className="project-details">
                {projectCost && <span className="project-cost" dangerouslySetInnerHTML={{__html: projectCost}} />}
                {projectClient && (
                  <div className="project-client detail-item">
                    <h3>Client</h3>
                    <span dangerouslySetInnerHTML={{__html: projectClient}} />
                  </div>
                )}
                {projectLocation && (
                  <div className="project-location detail-item">
                    <h3>Location</h3>
                    <span dangerouslySetInnerHTML={{__html: projectLocation}} />
                  </div>
                )}
                {projectDuration && (
                  <div className="project-duration detail-item">
                    <h3>Project Duration</h3>
                    <span dangerouslySetInnerHTML={{__html: projectDuration}} />
                  </div>
                )}
                {projectStartDate && (
                  <div className="project-start detail-item">
                    <h3>Project Start</h3>
                    <span dangerouslySetInnerHTML={{__html: projectStartDate}} />
                  </div>
                )}
                {projectFinishDate && (
                  <div className="project-finish detail-item">
                    <h3>Project Finish</h3>
                    <span dangerouslySetInnerHTML={{__html: projectFinishDate}} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {projectImageSlider && projectImageSlider.length > 0 && (
          <div className="project-image-slider">
            <div className="wrapper">
              <Carousel dots={false} infinite>
                {projectImageSlider.map(slide => (
                  <div className="project-image-slide" key={slide.image.id}>
                    <Image image={slide.image} alt="Project image" position="relative" />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        )}
        {(previousPost || nextPost) && (
          <div className="project-navigation">
            <div className="wrapper">
              <div className="previous">
                {previousPost && (
                  <Link to={`/${blogSlug}/${previousPost.slug}/`}>
                    <span>Prev Project</span>
                    <span>{decodeEntities(previousPost.title)}</span>
                  </Link>
                )}
              </div>
              <div className="next">
                {nextPost && (
                  <Link to={`/${blogSlug}/${nextPost.slug}/`}>
                    <span>Next Project</span>
                    <span>{decodeEntities(nextPost.title)}</span>
                  </Link>
                )}
              </div>
            </div>
          </div>
        )}

      </section>
      {relatedProjects && relatedProjects.edges && relatedProjects.edges.length > 0 && (
        <RecentProjects
          projects={relatedProjects.edges}
          title="Related"
          subtitle="Projects"
          content="<p>We’re proud of our achievements. Read about our recent projects and case studies to see what we’ve been working on and how we could work with you.</p>"
        />
      )}
      <Cta
        backgroundColor="dark"
        title="Our experts can make your next project a success."
        content={`
          <p>Discover how Aaro could provide you with expert solutions backed by experience.</p>
        `}
        image={{
          localFile: contactCtaImage
        }}
        buttonText="Get in touch"
        buttonLink="/contact-us/"
        wordpressUrl={wordpressUrl}
        isLastComponent
      />
    </Layout>
  );
}

export default Previewable(Project);

export const pageQuery = graphql`
  query ProjectById($id: String!, $categoryIds: [Int]) {
    site {
      siteMetadata {
        siteTitle: title
        blogSlug
        siteUrl
        wordpressUrl
        functionsUrl
      }
    }
    contactCtaImage: file(relativePath: { eq: "images/contact-worker.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    wordpressWpProjects(id: { eq: $id }) {
      title
      date(formatString: "DD MMM YYYY")
      categories {
        name
        slug
      }
      content
      featuredImage: featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      acf {
        projectIntro
        projectCost
        projectClient
        projectLocation
        projectDuration
        projectStartDate
        projectFinishDate
        projectImageSlider {
          image {
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
    }
    relatedProjects: allWordpressWpProjects(
      filter: {
        categories: { elemMatch: { wordpress_id: { in: $categoryIds } } }
        slug: { ne: "build-project" }
        id: { ne: $id }
      },
      limit: 9
    ) {
      edges {
        node {
          id,
          title
          content
          slug
          image: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          categories {
            name
            slug
          }
          date
          acf {
            recentProjectsCaption
            projectIntro
            projectCost
            projectClient
            projectLocation
          }
        }
      }
    }
  }
`;
