import React from 'react';
import Image from '../components/Image';
import './DescriptiveImage.scss';

export const DescriptiveImage = (props) => {
  const {
    image,
    title,
    content
  } = props;
  return (
    <section className="descriptive-image">
      <div className="wrapper descriptive-image-wrapper">
        <div className="content">
          <h2 dangerouslySetInnerHTML={{__html: title}} />
          <div className="content-body" dangerouslySetInnerHTML={{__html: content}} />
        </div>
        <div className="image-container">
          <Image image={image} />
        </div>
      </div>
    </section>
  );
}
