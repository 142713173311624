import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import BlogFeed from '../components/BlogFeed';

// How many more posts to show each time 'load more' is clicked
const LOAD_MORE_LIMIT = 7;

export default class BlogPage extends React.Component {
  render() {
    const { data, location } = this.props;
    const { site, allCategories, allPosts, allProjects } = data;
    const { title: siteTitle } = site.siteMetadata;
    const posts = allPosts ? allPosts.edges : [];
    const projects = allProjects ? allProjects.edges : [];
    const categories = allCategories ? allCategories.edges : [];

    return (
      <Layout location={location}>
        <SEO title={`News & Projects | ${siteTitle}`} />
        <BlogFeed
          categories={categories}
          posts={posts}
          projects={projects}
          loadMoreLimit={LOAD_MORE_LIMIT}
        />
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        blogSlug
        title
      }
    }
    allCategories: allWordpressCategory(filter: {count: {gt: 0}}) {
      edges {
        node {
          name
          slug
        }
      }
    }
    allPosts: allWordpressPost(sort: { fields: date, order: DESC }, filter: { slug: { ne: "build-post" } }) {
      edges {
        node {
          title
          slug
          date(formatString: "dddd, MMMM YYYY, h:mm:ss")
          featuredImage: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          categories {
            slug
          }
        }
      }
    }
    allProjects: allWordpressWpProjects(filter: { slug: { ne: "build-project" } }) {
      edges {
        node {
  				title
          slug
          date(formatString: "dddd, MMMM YYYY, h:mm:ss")
          featuredImage: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          categories {
            slug
          }
        }
      }
    }
  }
`
