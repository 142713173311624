import React, { Component } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Image from '../components/Image';
import grid from '../images/grid.svg';
import { HeaderLogo } from '../components/HeaderLogo';
// import { SearchForm } from '../components/SearchForm';
// import { Filter } from '../components/Filter';
import './Services.scss';
import './Header.scss';

class ServicesComponent extends Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    const { services = null } = data;
    this.state = {
      searchQuery: '',
      allServices: services.edges,
      activeServices: services.edges,
      activeFilter: null
    };
  }

  onSearchFormChange = (e) => {
    const { allServices } = this.state;
    this.setState({ searchQuery: e.target.value });
    if (e.target.value === '') this.setState({ activeServices: allServices });
  }

  onSearchFormSubmit = (e) => {
    e.preventDefault();
    // Filter services
    const { searchQuery, allServices = null } = this.state;
    const filteredServices = allServices ? allServices.filter(({ node: item }) => {
      return item.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
    }) : null;
    this.setState({ activeServices: filteredServices });
  }

  getFilterOptions = () => {
    const { data: { services } } = this.props;
    const filterOptions = services.edges.map(({node: service}) => {
      return {
        val: service.slug,
        label: service.title,
      };
    });
    return filterOptions;
  }

  onFilterChange = (filterOption) => {
    return this.setState({ activeFilter: filterOption });
  }

  render() {
    const {
      backgroundImage,
      titleFirstLine,
      titleSecondLine,
      content,
      data
    } = this.props;
    const { site: { siteMetadata: { servicesSlug } } } = data;
    const { activeServices, activeFilter } = this.state;
    const servicesToDisplay = activeFilter ?
      activeServices.filter(({node: service}) => service.slug === activeFilter) :
      activeServices;
    return (
      <section className="services-block">
        <div className="main-header">
          <HeaderLogo />
          <div className="main-header-container">
            <Image image={backgroundImage} />
            <div className="wrapper">
              <div className="main-header-content">
                <h1>
                  {titleFirstLine}
                  <span>{titleSecondLine}</span>
                </h1>
                <div className="main-header-content-body" dangerouslySetInnerHTML={{ __html: content }} />
                {/* <SearchForm
                  onChange={this.onSearchFormChange}
                  onSubmit={this.onSearchFormSubmit}
                /> */}
                {/* <Filter
                  options={this.getFilterOptions()}
                  onChange={this.onFilterChange}
                /> */}
              </div>
            </div>
            <img src={grid} className="grid-texture" alt="One group, many services" />
          </div>
        </div>
        {servicesToDisplay.length > 0 ? (
          <div className={`services ${servicesToDisplay % 2 === 0 ? 'extra-padding-bottom' : ''}`}>
            <div className="wrapper">
              {servicesToDisplay.map(({node: service}) => {
                const { title, image, acf: { serviceIntro, shortDescription } } = service;
                return (
                  <div className="service" key={title}>
                    <div className="image">
                      <Image image={image} position="absolute" />
                    </div>
                    <h2 dangerouslySetInnerHTML={{__html: title}} />
                    {(shortDescription || serviceIntro) && <div className="content" dangerouslySetInnerHTML={{__html: shortDescription || serviceIntro}} />}
                    <Link className="button" to={`${service.path}`}>Learn more</Link>
                  </div>
                );
              })}
            </div>
          </div>
        ) : <div className="no-results"><div className="wrapper">{'Sorry, we couldn\'t find any matching results.'}</div></div>}
      </section>
    );
  }
}

export function Services(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              servicesSlug
            }
          }
          services: allWordpressWpServices(sort: {fields: date, order: ASC}) {
            edges {
              node {
                title
                content
                slug
                path
                image: featured_media {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1200, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                acf {
                  serviceIntro
                  shortDescription
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <ServicesComponent data={data} {...props} />
      )}
    />
  );
}
