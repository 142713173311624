import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Carousel } from './Carousel';
import { decodeEntities } from '../utils/htmlParse';
import Image from './Image';
import './ProjectSlider.scss';

export default class ProjectSlider extends Component {
  state = { marginLeft: 0 };

  componentDidMount() {
    this.getSliderOffset();
    window.addEventListener('resize', this.getSliderOffset);
  }

  componentDidUpdate(prevProps, prevState) {
    const { slider: oldSlider } = prevState;
    const { slider: newSlider } = this.state;
    if (oldSlider !== newSlider) this.getSliderOffset();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getSliderOffset);
  }

  getSliderOffset = () => {
    const wrapper = document.getElementById('project-slider-offset');
    const dots = document.querySelector('.slider-block .slick-dots');
    if (!wrapper) return null;
    const { offsetLeft } = wrapper;
    const adjustedOffsetLeft = offsetLeft + 80;
    const paddingLeftStr = window.getComputedStyle(wrapper, null).getPropertyValue('padding-left');
    const paddingLeftInt = paddingLeftStr ? paddingLeftStr.replace('px', '') : 0;
    this.setState({ marginLeft: adjustedOffsetLeft + parseInt(paddingLeftInt, 10) });
    if (dots) {
      dots.style.right = `${adjustedOffsetLeft}px`;
    }
    return null;
  }

  render() {
    const { projects, buttonText, buttonLink } = this.props;
    const { marginLeft } = this.state;
    let dragging = false
    return (
      <section className="project-slider" style={{ marginLeft }}>
        <Carousel slidesToShow={1} slidesToScroll={1} beforeChange={() => { dragging = true; }} afterChange={() => { dragging = false; }} infinite variableWidth>
          {projects.map((project) => {
            const { id, slug, image, cost, title, location, duration, category, caption, date } = project;
            return (
              <div className="project-slide" key={id}>
                <Link to={`/blog/${slug}/`} onClick={event => dragging && event.preventDefault()} className="project-slide-image">
                  <Image image={image} />
                </Link>
                <div className="project-slide-content">
                  {cost && <span className="project-slide-cost">{decodeEntities(cost)}</span>}
                  {title && (
                    <Link to={`/blog/${slug}/`} onClick={event => dragging && event.preventDefault()} className="project-slide-title">
                      {decodeEntities(title)}
                    </Link>
                  )}
                  {category && <span className="project-slide-category">{decodeEntities(category)}</span>}
                  {title && <div className="project-slide-intro" dangerouslySetInnerHTML={{ __html: caption }} />}
                </div>
                <div className="project-slide-meta">
                  {duration && <span className="project-slide-duration">{decodeEntities(duration)}</span>}
                  {location && <span className="project-slide-location">{decodeEntities(location)}</span>}
                </div>
              </div>
            );
          })}
        </Carousel>
        {buttonLink && buttonText && (
          <div className="project-slider-actions wrapper">
            <Link className="button dark" to={buttonLink}>{decodeEntities(buttonText)}</Link>
          </div>
        )}
      </section>
    );
  }
}
