import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { decodeEntities } from '../utils/htmlParse';
import { Navigation } from './Navigation';
import logoTagline from '../images/logo-tagline.svg';
import grid from '../images/grid.svg';
import { SocialLink } from './SocialLink';
import './Footer.scss';

function scrollToTop(event) {
  if (event) event.preventDefault();
  document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
}

export const Footer = ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              servicesSlug
            }
          }
          wordpressAcfOptions {
            options {
              socialLinks {
                type
                url
              }
              phone: phoneNumber
              address: addressDisplay
              email: emailAddress
            }
          }
          services: allWordpressWpServices(sort: {fields: menu_order, order: ASC}) {
            edges {
              node {
                title
                slug
                acf {
                  shortTitle
                }
                tags {
                  slug
                }
              }
            }
          }
          allTags: allWordpressTag {
            edges {
              node {
                name
                slug
              }
            }
          }
          privacyPolicyPage: wordpressPage(wordpress_id: { eq: 3 }) {
            slug
          }
        }
      `}
      render={(data) => {
        const isOnHomepage = location && location.pathname === '/';
        const { wordpressAcfOptions: { options }, services: { edges: services }, allTags, site, privacyPolicyPage } = data;
        const { siteMetadata: { servicesSlug } } = site;
        const { socialLinks, phone, address, email } = options;
        const date = new Date();
        const currentDate = date.getFullYear();
        // Group services by their tag (maintenance, rehabilitation or relining)
        const servicesCols = services.reduce((acc, {node}) => {
          (acc[node.tags[0].slug] = acc[node.tags[0].slug] || []).push(node);
          return acc;
        }, {});
        return (
          <footer id="main-footer">
            <div className="wrapper">
              <div className="main-footer-top">
                <div className="col logo-col">
                  <Link to="/" className="footer-logo">
                    <img src={logoTagline} alt="Aaro" />
                  </Link>
                  <div className="contact-details">
                    <span className="address" dangerouslySetInnerHTML={{__html: address}} />
                    <a href={`tel:${phone}`} className="phone">{phone}</a>
                    <a href={`mailto:${email}`} className="email">{email}</a>
                  </div>
                </div>
                <div className='col footer-navigation'>
                  <Navigation />
                </div>
                <div className="col footer-services">
                  <ul>
                    {services && services.map(({node: service}) => {
                      const serviceTitle = service.acf && service.acf.shortTitle ? service.acf.shortTitle : service.title;
                      return (
                        <li className="service" key={service.slug}>
                          <Link to={`/${servicesSlug}/${service.slug}/`}>{decodeEntities(serviceTitle)}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="main-footer-bottom">
                <div className="main-footer-bottom-right">
                  {socialLinks && (
                    <div className="social-links">
                      <span className="privacy"><Link to={`/${privacyPolicyPage.slug}/`}>Privacy Policy</Link></span>
                      {socialLinks.map((link, index) => (
                        <SocialLink size="smaller" theme="light" socialLink={link} key={`${link.url}--${index}`} />
                      ))}
                    </div>
                  )}
                  <div className="copyright">
                    <span>&copy; {currentDate} Copyright Aaro Group. All rights reserved.</span> {/* eslint-disable-line */}
                    <span className="by-birdbrain">
                      Website by&nbsp;
                      {isOnHomepage ?
                        <a href="https://distl.com.au/" target="_blank" rel="noopener noreferrer">Distl</a> :
                        'Distl'
                      }
                      .
                    </span> {/* eslint-disable-line */}
                  </div>
                  <button
                    type="button"
                    className="scroll-top-button"
                    onClick={event => scrollToTop(event)}
                    aria-label="Back to top"
                  >
                    <span>Back to top</span>
                  </button>
                </div>
              </div>
            </div>
            <img src={grid} className="grid-texture" alt="One group, many services" />
          </footer>
        );
      }}
    />
  );
}
