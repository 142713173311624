import React from 'react';
import Helmet from 'react-helmet';

export const Twitter = ({ type, username, title, desc, image }) => (
  <Helmet>
    <meta name="twitter:card" content={type} />
    {username && <meta name="twitter:creator" content={username} />}
    {title && <meta name="twitter:title" content={title} />}
    {desc && <meta name="twitter:description" content={desc} />}
    {image && <meta name="twitter:image" content={image.source_url} />}
  </Helmet>
);

export default Twitter;
