import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Carousel } from '../components/Carousel';
import Image from '../components/Image';
import './Logos.scss';

const MOBILE_VIEWPORT_SIZE = 700;

export class Logos extends Component {
  state = {
    slidesToShow: 4
  }

  componentDidMount() {
    this.updateNumSlidesToShow();
    window.addEventListener('resize', this.updateNumSlidesToShow);
  }

  updateNumSlidesToShow = () => {
    const slidesToShow = window.innerWidth > MOBILE_VIEWPORT_SIZE ? 4 : 2;
    this.setState({ slidesToShow });
  }

  render() {
    const { slidesToShow } = this.state;
    const { title, logos } = this.props;
    let tempSlide = [];
    const isOdd = (index) => index % 2 === 0;
    const slides = logos.reduce((acc, next, index) => {
      tempSlide.push(next);
      if (!isOdd(index)) {
        acc.push(tempSlide);
        tempSlide = [];
      }
      if (index === logos.length - 1 && isOdd(index)) acc.push(tempSlide);
      return acc;
    }, []);
    return (
      <section className="logos">
        <div className="wrapper">
          <h2 dangerouslySetInnerHTML={{__html: title}} />
          <Carousel slidesToShow={slidesToShow} slidesToScroll={1} dots={false}>
            {slides.map((slide, slideIndex) => (
              <React.Fragment key={slideIndex}>
                {slide.map(logo => (
                  <div className="logo-image" key={logo.name}>
                    <Image image={logo.logo} alt={logo.name} objectFit="contain" />
                  </div>
                ))}
              </React.Fragment>
            ))}
          </Carousel>
        </div>
      </section>
    );
  }
}
