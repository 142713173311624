import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Image from '../components/Image';
import './Locations.scss';

export class Locations extends Component {
  state = {
    activeIndex: 0
  }

  handleClick = (index) => {
    this.setState({ activeIndex: index });
  }

  render() {
    const {
      title
    } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query {
            wordpressAcfOptions {
              options {
                contactLocations {
                  location
                  locationDetails
                  map {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1200, quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={({ wordpressAcfOptions: { options: { contactLocations } } }) => {
          if (!contactLocations) return null;
          const { activeIndex } = this.state;
          return (
            <section className="locations-block">
              <div className="wrapper locations-wrapper">
                <div className="locations">
                  <div className="details">
                    <h2 dangerouslySetInnerHTML={{__html: title}} />
                    {contactLocations.map((location, index) => {
                      return (
                        <div className={`location ${activeIndex === index ? 'active' : ''}`}>
                          <h3 dangerouslySetInnerHTML={{__html: location.location}} />
                          <div className="location-details" dangerouslySetInnerHTML={{__html: location.locationDetails}} />
                        </div>
                      );
                    })}
                  </div>
                  <div className="map-container">
                    <div className="map">
                      {contactLocations.map((location, mapIndex) => (
                        <Image image={contactLocations[mapIndex].map} className={`${activeIndex === mapIndex ? 'active' : ''}`} position="relative" />
                      ))}
                    </div>
                    <div className="tabs-container">
                      <div className="tabs">
                        {contactLocations.map((location, tabIndex) => (
                          <button
                            type="button"
                            className={`tab ${activeIndex === tabIndex ? 'active' : ''}`}
                            onClick={() => this.handleClick(tabIndex)}
                          >
                            <span dangerouslySetInnerHTML={{__html: location.location}} />
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          );
        }}
      />
    );
  }
}
