import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Navigation } from './Navigation';
import { SocialLink } from './SocialLink';
import './TopNavigation.scss';

export default class TopNavigation extends Component {
  state = {
    navOpen: false,
    observer: null
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleEscKey, false);
    document.addEventListener('click', this.handleModalBackgroundClick, false);
    window.addEventListener('beforeunload', this.onUnload);
    // Set navOpen to false when 'nav-open' class is removed from the body element
    const bodyElement = document.body;
    const observer = new MutationObserver((event) => {
      if (event[0].target.className === 'nav-open') {
        this.setState({ navOpen: true });
      } else {
        this.setState({ navOpen: false });
      }
    });
    observer.observe(bodyElement, {
      attributes: true,
      attributeFilter: ['class'],
      childList: false,
      characterData: false
    });
    this.setState({ observer });
  }

  componentWillUnmount() {
    const { observer } = this.state;
    document.removeEventListener('keydown', this.handleEscKey, false);
    document.removeEventListener('click', this.handleModalBackgroundClick, false);
    window.removeEventListener('beforeunload', this.onUnload);
    document.body.classList.remove('nav-open');
    observer.disconnect();
  }

  handleEscKey = event => {
    if (event.keyCode === 27) {
      document.body.classList.remove('nav-open');
      this.setState({ navOpen: false });
    }
  };

  handleModalBackgroundClick = event => {
    if (!event || !event.target) return null;
    const { className } = event.target;
    if (className === 'nav-open') {
      return this.closeMenu();
    }
    return null;
  };

  closeMenu = () => {
    this.setState({ navOpen: false });
    document.body.classList.remove('nav-open');
  }

  toggleMenu = () => {
    const { navOpen } = this.state;
    this.setState({ navOpen: !navOpen });
    document.body.classList.toggle('nav-open');
  }

  render() {
    const { navOpen } = this.state;
    return (
      <StaticQuery
        query={graphql`
          query {
            wordpressAcfOptions {
              options {
                socialLinks {
                  type
                  url
                }
                phoneNumber
              }
            }
          }
        `}
        render={(data) => {
          const { wordpressAcfOptions: { options } } = data;
          const {
            socialLinks,
            phoneNumber
          } = options;
          const { location } = this.props;
          return (
            <div className={`top-navigation ${navOpen ? 'menu-active' : ''}`}>
              <button
                className="menu-toggle open-menu"
                type="button"
                onClick={this.toggleMenu}
              >
                <span className="hamburger" />
              </button>
              <div className="menu-panel">
                <Navigation closeMenu={this.closeMenu} location={location} />
                <div className="menu-panel-bottom">
                  {phoneNumber && <a className="telephone" href={`tel:${phoneNumber}`}>T {phoneNumber}</a>}
                  {socialLinks && (
                    <ul className="social-links">
                      {socialLinks.map((link, index) => (
                        <SocialLink theme="light" socialLink={link} key={`${link.url}--${index}`} />
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      />
    );
  }
}
