import React from 'react';
import { graphql, Link } from 'gatsby';
import grid from '../images/grid.svg';
import lightGrid from '../images/grid-light.svg';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Image from '../components/Image';
// import { RecentProjects } from '../acf/RecentProjects';
import { FormBlock } from '../acf/FormBlock';
import { HeaderLogo } from '../components/HeaderLogo';
import Previewable from '../components/Previewable';
import { SliderBlock } from '../acf/SliderBlock';
import { getExcerpt, decodeEntities } from '../utils/htmlParse';
import './project.scss';
import './service.scss';
import '../acf/SliderBlock.scss';

const Service = ({ data, location }) => {
  const { site, wordpressWpServices: service, otherServices = {}, sliderBackgroundImage, relatedProjects } = data;
  if (!service) return null;
  const { siteMetadata: { siteTitle, servicesSlug, wordpressUrl } } = site;
  // const { previous: previousPost, next: nextPost } = pageContext;
  const { title, content, featuredImage, yoast, acf = {} } = service;
  const { serviceIntro } = acf;
  const otherServiceSlides = otherServices.edges && otherServices.edges.map(({node: otherService}) => {
    return ({
      slideTitle: otherService.title,
      slideSubtitle: getExcerpt(otherService.content, 110),
      slideImage: otherService.image,
      slideButtonLink: `/${servicesSlug}/${otherService.slug}/`,
      slideButtonText: 'Learn more',
      icon: 'none'
    });
  });
  return (
    <Layout location={location} className="service-template">
      <SEO
        title={`${decodeEntities(yoast.metaTitle) || decodeEntities(title)} | ${decodeEntities(siteTitle)}`}
        desc={yoast.metaDescription}
      />
      <header className="project-header">
        <HeaderLogo />
        <img src={grid} className="grid-texture" alt="One group, many services" />
        <img src={grid} className="grid-texture" alt="One group, many services" />
      </header>
      <section className="project-content">
        <div className="project-content-top">
          <div className="wide-wrapper">
            <div className="wrapper">
              <div className="project-intro">
                <div>
                  <span className="date" />
                  <span className="category" />
                </div>
                <h1 dangerouslySetInnerHTML={{__html: title}} />
                <span className="subtitle" dangerouslySetInnerHTML={{__html: serviceIntro}} />
              </div>
            </div>
            <div className="featured-image">
              <Image image={featuredImage} />
            </div>
          </div>
        </div>
        <div className="project-body">
          <div className="wide-wrapper">
            <div className="wrapper">
              <div className="content-container">
                <div className="content" dangerouslySetInnerHTML={{__html: content}} />
                <Link className="button dark" to={`/${servicesSlug}/`}>Find out more about our 24/7 services</Link>
              </div>
              <div className="project-details" />
            </div>
            <img src={lightGrid} className="grid-texture" alt="Service body texture" />
          </div>
        </div>
      </section>
      {/* {relatedProjects && relatedProjects.edges && relatedProjects.edges.length > 0 && (
        <RecentProjects
          projects={relatedProjects.edges}
          title="Related"
          subtitle="projects"
          paragraph="We’re proud of our achievements. Read about our recent projects
          and case studies to see what we’ve been working on and how we could work
          with you."
        />
      )} */}
      {otherServices && (
        <SliderBlock
          title='View our other services'
          image={{localFile: sliderBackgroundImage}}
          hasButton={false}
          slides={otherServiceSlides}
          wordpressUrl={wordpressUrl}
        />
      )}
      <FormBlock
        title="Our experts can make your next project a success."
        subtitle="Visit the contact page for more information."
        form="contact"
        location={location}
      />
    </Layout>
  );
}

export default Previewable(Service);

export const pageQuery = graphql`
  query ServiceById($id: String!, $serviceSlug: String!) {
    site {
      siteMetadata {
        siteTitle: title
        servicesSlug
        siteUrl
        wordpressUrl
        functionsUrl
      }
    }
    sliderBackgroundImage: file(relativePath: { eq: "images/tractor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wordpressWpServices(id: { eq: $id }) {
      title
      content
      slug
      featuredImage: featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      acf {
        serviceIntro
      }
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
    }
    otherServices: allWordpressWpServices(
      filter: { id: { ne: $id } },
      limit: 9
    ) {
      edges {
        node {
          id
          title
          content
          slug
          image: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1600, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          # acf {
          #   serviceIntro
          # }
        }
      }
    }
    relatedProjects: allWordpressWpProjects(filter: { categories: { elemMatch: { slug: { eq: $serviceSlug } } } }) {
      edges {
        node {
          title
          content
          slug
          image: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1600, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          categories {
            name
            slug
          }
          date
          acf {
            recentProjectsCaption
            projectIntro
            projectCost
            projectClient
            projectLocation
          }
        }
      }
    }
  }
`;
