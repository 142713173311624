import React, { Component } from 'react';
import * as Forms from '../components/Forms';
import grid from '../images/grid.svg';
import './FormBlock.scss';

export class FormBlock extends Component {
  componentDidMount() {
    const { location } = this.props;
    if (location.search === '?success=true') {
      document.body.classList.add('form-submitted');
      setTimeout(() => document.body.classList.remove('form-submitted'), 3000);
    }
  }

  getForm = (form) => {
    const { location } = this.props;
    switch(form) {
      case 'contact':
        return <Forms.Contact location={location} />;
      default:
        return null;
    }
  }

  render() {
    const {
      title,
      subtitle,
      form
    } = this.props;
    return (
      <section className="form-block">
        <div className="wrapper form-block-wrapper">
          <div className="text-container">
            <h2 className="title">{title}</h2>
            {subtitle && <div className="subtitle" dangerouslySetInnerHTML={{__html: subtitle}} />}
          </div>
          <div className="form-container">
            {this.getForm(form)}
          </div>
        </div>
        <div className="bottom-separator">
          <div className="wrapper">
            <img src={grid} className="grid-texture" alt="Contact form texture" />
          </div>
        </div>
      </section>
    );
  }
}
